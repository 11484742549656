<template>
  <IndexView @clicked="search" 
             v-bind:error-message="this.errorMessage" 
             v-bind:error-occured="this.errorOccured"
             v-bind:is-loading="this.isLoading"
             v-if="!gotId"/>
  <generic-shipment-view 
      v-bind:my-shipment="this.myShipmentData"
      @failedLoad="failedLoad"
      v-else/>
</template>

<script>
import IndexView from "@/components/IndexView";
import genericShipmentView from "@/components/genericShipmentView";
import shipmentApi from '../Services/httpService'


export default {
  name: "viewSwitcher",
  components: {IndexView, genericShipmentView},
  props: {},
  data: function () {
    return {
      gotId: false,
      errorMessage: '',
      errorOccured: false,
      isLoading: false,
      myShipmentData: {}
    }
  },
  async mounted() {

      //if ('/p/locateshipment/') { }

      if (window.location.pathname.startsWith('/p/locateshipment/')) {

          const customerExternalId = window.location.pathname.split('/')[3];
          const waybillExternalId = window.location.pathname.split('/')[4];

          this.locateMyShipment(customerExternalId, waybillExternalId);

      } else if (window.location.pathname.startsWith('/p/locatereference/')) {

          const customerExternalId = window.location.pathname.split('/')[3];
          const alternateExternalWaybillID = window.location.pathname.split('/')[4];

          this.locateMyShipmentByAlternateExternalWaybillID(customerExternalId, alternateExternalWaybillID);

      } else {

          const id = window.location.pathname.split('/')[1];

          if (/\S/.test(id)) {
              this.loadMyShipment(id);
          }
      }

  },
  methods: {
    search(inputValue)
    {
      if (/\S/.test(inputValue)) {
        window.history.pushState('', '', '/'+ inputValue);
        this.loadMyShipment(inputValue)
      }
    },
    failedLoad(statusCode)
    {
      if (statusCode === 404)
      {
        this.errorMessage = this.$t("errorCode.404")
      }
      else if (statusCode === 500)
      {
        this.errorMessage = this.$t("errorCode.500")
      }
      else
      {
        this.errorMessage = this.$t("errorCode.unknown")
      }
      this.errorOccured = true
      this.gotId = false
    },
    loadMyShipment(id)
    {
      this.isLoading = true;
      shipmentApi.get("api/myshipment/" + id)
          .then((response) => {
            this.errorOccured = false
            this.isLoading = false;
            this.myShipmentData = response.data
            this.gotId = true;
          })
          .catch((error) => {
            this.isLoading = false;
            this.failedLoad(error.response.status)
          })
      },
      locateMyShipment(customerExternalId, waybillExternalId) {
          this.isLoading = true;

          shipmentApi.get("api/myshipment/ByExternalWaybill/" + customerExternalId + '/' + waybillExternalId)
              .then((response) => {
                  this.errorOccured = false
                  this.isLoading = false;
                  this.myShipmentData = response.data
                  this.gotId = true;
              })
              .catch((error) => {
                  this.isLoading = false;
                  this.failedLoad(error.response.status)
              })
      },

      locateMyShipmentByAlternateExternalWaybillID(customerExternalId, alternateExternalWaybillId) {

          this.isLoading = true;

          shipmentApi.get("api/myshipment/ByAlternateExternalWaybill/" + customerExternalId + '/' + alternateExternalWaybillId)
              .then((response) => {
                  this.errorOccured = false
                  this.isLoading = false;
                  this.myShipmentData = response.data
                  this.gotId = true;
              })
              .catch((error) => {
                  this.isLoading = false;
                  this.failedLoad(error.response.status)
              })
      }

  },
  computed: {
   
  },
  filters: {}
}

</script>

<style scoped>

</style>