<template>
  <div>

    <b-container class="wrapper d-flex h-100 flex-column" style="justify-content: center;">

      <!-- <b-row class="header-row justify-content-md-center">
        
        <b-col cols="12">
          
          <h1 class="text-logo mx-auto d-block w-75">
            Min forsendelse
          </h1>

        </b-col>
        
      </b-row> -->
<!-- 
      <b-row class="header-row justify-content-md-center" style="display: flex;align-items: center;">
        
        <b-col cols="2">
          
          <div class="gfx-logo mx-auto" style="display:inline-block">
            <b-img :src=icon />
          </div>

        </b-col>

        <b-col cols="6">
          
          <h1 class="text-logo mx-auto d-block" style="display:inline-block">
            Min forsendelse
          </h1>

        </b-col>
        
      </b-row>  -->

      <b-row class="mt-4">
        
        <h1 class="text-logo mx-auto d-block w-75">
            Min forsendelse - Track & Trace
        </h1>

        
      </b-row>
      
        <b-row class="mt-5">

          <B-col>
            <p>{{this.$t("message.trackAndTraceDescription")}}</p>
          </B-col>

        </b-row>
      <template v-if="!isLoading">

        <b-row class="mt-5 justify-content-md-center">
          <b-col xs="8" md="10" lg="8" class="input-row">
            <b-form-input v-model="inputText" class="form-control float-right"/>
          </b-col>
          <b-col xs="4" md="2" lg="2" class="button-row">
            <b-button v-text="this.$t('message.searchBtnText')" class="logo-button form-control float-right" variant="primary" v-on:click="search"/>
          </b-col>
        </b-row>

        <b-row v-if="this.errorOccured">
          <b-col>
            <p class="error-text">{{this.errorMessage}}</p>
          </b-col>
        </b-row>
      </template>
      
      <template v-else>
        <b-row>
          <b-col class="loading-spinner">
            <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
          </b-col>
        </b-row>
      </template>
      
      

    </b-container>
  </div>
</template>

<script>

export default {
  name: 'IndexView',
  components: {},
  props: {
    errorMessage: {
      default: 'Unknown Error Has Occured',
      type: String
    },
    errorOccured: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      inputText: '' 
    }
  },
  async mounted() 
  {
    
  },
  methods: {
    search()
    {
      this.$emit('clicked', this.inputText);
    }
  },
  computed: {
    icon() {
      return require("@/assets/gfx/open-box.svg")
    },
  },
  filters: {}
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background-color: #FDF9F8;
}

.gfx-logo{  
  
  border-radius: 50%!important;

  height: 1vw;
  /* height: 30vw;
  max-height: 30vw;
  max-width: 30vw; */

}
.gfx-logo > img {
  width: 100%;
  height: 100%;
  margin-left: 5vw;
}

.gfx-logo > img  {
  stroke: #FFFFFF;
  
}

.text-logo{
  font-size: calc(100% + 1vw + 1vh);
  font-weight:300;
}

.wrapper {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  background-color: white;
  border-radius: 0.5rem;
  min-height: 80vh;
  flex: 1 1 auto;
  padding: 0 2.5rem 2.5rem 2.5rem;
  margin-top: 5vh;
}

.wrapper > .row {
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-row {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.primary-row > div {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 576px) {
  
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 320px) and (min-width: 280px) {

}

@media screen and (max-width: 576px) and (min-width: 406px) {

  
}

@media screen and (max-width: 767px) and (min-width: 577px) {
 
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  
}


</style>
