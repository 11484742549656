import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import myshipmentPlugin from './plugins/skanlog-myshipment';
import App from './App.vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
Vue.use(myshipmentPlugin)
Vue.config.productionTip = false

const messages = {
  da: {
    publicTracking:{
      P1: "Klargjort",
      P2: "Behandles",
      P3: "Undervejs",
      P4: "Klar til afhentning",
      P5: "Leveret",
      P6: "Udleveret",
      P7: "Levering ej muligt"
    },
    publicTrackingDescription:{
      P1: "Forsendelsesdata er modtaget fra afsender",
      P2: "Forsendelsen er på vores terminal",
      P3: "Forsendelsen er nu på vej til dig",
      P4: "Du kan hente den i pickup pointet",
      P5: "Forsendelsen er nu afleveret hos dig",
      P6: "Du har afhentet forsendelsen",
      P7: ""
    },
    message: {
      needHelp: "Brug for hjælp?",
      eventHistoryText: "Forsendelseshistorik",
      needHelpDescription: "Hvis du har brug for hjælp i forbindelse med leveringen, så kan du kontakte vores kundeservice",
      deliveryHandledBy: "Forsendelsen leveres af",
      followDelivery: "Følg forsendelsen",
      today: "Idag",
      monday: "Mandag",
      tuesday: "Tirsdag",
      wednesday: "Onsdag",
      thursday: "Torsdag",
      friday: "Fredag",
      saturday: "Lørdag",
      sunday: "Søndag",
      lowerInfoTextCurrentDay: "Opdateres løbende",
      lowerInfoTextNotToday: "Opdateres løbende",
      expectDelivery: "FORVENTET LEVERING",
      tomorrow: "I morgen",
      inDays: "Om {days} dage",
      delivered: "Afleveret",
      loading: "Indlæser",
      container: "Pakke",
      noeventsfound:"Ingen historik fundet",
      showfulleventhistory: "Fuld historik",
      hidefulleventhistory: "Fuld historik",
      searchBtnText: "Søg",
      trackAndTraceDescription: "Her kan du følge dine pakker ved at indsætte dit forsendelses-ID."

    },
    errorCode: {
      "404": "Vi kunne ikke finde en pakke med det nummer",
      "500": "Der skete en uventede fejl, prøv igen senere",
      "unknown": "Der skete en ukendt fejl, prøv igen senere"
    }
   
  },
  en:{
    publicTracking:{
      P1: "Created",
      P2: "Processing",
      P3: "In-Transit",
      P4: "Ready for pickup",
      P5: "Delivered",
      P6: "Delivered",
      P7: "Delivery attempt failed"
    },
    publicTrackingDescription:{
      P1: "Shipment is still with sender, will be picked up soon",
      P2: "Shipment was sorted in our sorting facility",
      P3: "Shipment is on the way to you",
      P4: "You can pick it up in the pickup point",
      P5: "The shipment is now with you",
      P6: "Shipment has been picked up from the pickup point",
      P7: ""
    },
    message: {
      needHelp: "In need of help?",
      eventHistoryText: "Shipment history",
      needHelpDescription: "If you're in need of help please the senders customer service",
      deliveryHandledBy: "The delivery is handled by",
      followDelivery: "Follow the delivery",
      today: "Today",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      lowerInfoTextCurrentDay: "Will be continuously updated",
      lowerInfoTextNotToday: "Please comeback on delivery day",
      expectDelivery: "EXPECTED DELIVERY",
      tomorrow: "Tomorrow",
      inDays: "In {days} days",
      delivered: "Delivered",
      loading: "Loading",
      container: "Package",
      noeventsfound: "No event history found",
      showfulleventhistory: "Full history",
      hidefulleventhistory: "Full history",
      searchBtnText: "Search",
      trackAndTraceDescription: "Insert your delivery ID you have received to track your package"
    },
    errorCode: {
      "404": "Sorry. We could not find the package with given number. Please try again",
      "500": "An unexpected error has occured. Please try again later",
      "unknown": "An unknown error has occured. Please try again later"
    }
  },
  nb: {
    message: {
      needHelp: "Trenger hjelp?",
      needHelpDescription: "Hvis du trenger hjelp med leveransen, kan du kontakte vår kundeservice",
      deliveryHandledBy: "FORSENDELSEN BEHANDLES AV",
      followDelivery: "Følg forsendelsen",
      today: "I dag",
      monday: "Mandag",
      tuesday: "Tirsdag",
      wednesday: "Onsdag",
      thursday: "Torsdag",
      friday: "Fredag",
      saturday: "Lørdag",
      sunday: "Søndag",
      lowerInfoTextCurrentDay: "Tidene blir kontinuerlig oppdatert",
      lowerInfoTextNotToday: "kom tilbake på leveringsdagen",
      expectDelivery: "FORVENTET LEVERING",
      tomorrow: "I morgen",
      inDays: "Om {days} dager",
      delivered: "overlevert"
    }
  }
}

const i18n = new VueI18n({
  locale: 'da', // set locale
  messages, // set locale messages
  fallbackLocale: "da"
})


new Vue({
  render: h => h(App),
  i18n
}).$mount('#app')
