<template>
  <div :class="this.clientId">

    <b-container class="wrapper d-flex h-100 flex-column">
      <b-row class="header-row">
        <b-col cols="0" sm="1" md="4">
        </b-col>
        <b-col cols="12" sm="10" md="4">
          <img class="mx-auto d-block w-75" :src='iconSmall'/>
        </b-col>
        <b-col cols="0" sm="1" md="4">
        </b-col>
      </b-row>

      <!--
      <b-row class="top-row mb-3 mt-3">
        <div style="height: 75px;">
          <step-progress-bar/>
        </div>
      </b-row>
      -->

      <b-row class="primary-row flex-fill d-flex">

        <b-col cols="12" md="6" lg="4" order-md="1">
          <b-row class="ETARow">
            
            <b-col cols="12" class="ETADiv"
                   v-if="Object.keys(shipmentData).length === 0 && shipmentData.constructor === Object">
              <div>
                  <p>{{ $t("message.loading") }}</p>
                <b-spinner type="grow"></b-spinner>
              </div>
            </b-col>

            <b-col cols="12" class="ETADiv" v-else-if="showAta">

              <p v-if="this.delivered" class="delivered-text">
                  <span class="icon check-ok" aria-hidden="true"></span>
                  {{ $t("message.delivered") }}
              </p>

              <p v-if="this.delivered" class="delivered-time">
                  {{ deliveredAt  | formatDateLong }}
              </p>

              <template v-else>

                  <p>
                      {{ $t("message.expectDelivery") }}
                  </p>

                  <template v-if="!packageTimingDiffer">
                      <p v-if="shipmentData.fromDate == null" class="delivery-time">
                          {{ shipmentData.toDate | formatTimeShort }}
                      </p>
                      <p v-else-if="deliveryToday" class="delivery-time">
                          {{ shipmentData.fromDate | formatTimeShort }}
                          -
                          {{ shipmentData.toDate | formatTimeShort }}
                      </p>
                      <p v-else-if="deliveryTomorrow" class="delivery-time">
                          {{ $t("message.tomorrow") }}
                      </p>
                      <p v-else-if="deliveryThisWeek" class="delivery-time">
                          {{ getDayInText }}
                      </p>
                      <p v-else-if="deliveryNextWeekOrLater" class="delivery-time">
                          {{
                      $t("message.inDays", {
                        days: calculateDaysBetween
                      })
                          }}
                      </p>
                  </template>
                  <template v-else>
                      <template v-for="(packageElement, index) in packages">

                          <h6 :key="index + 'headline'" class="package-title">{{ $t("message.container") }} {{ index + 1 }}</h6>
                          
                          <p v-if="packageElement.fromDate == null" :key="index" class="delivery-time">
                              {{ packageElement.toDate | formatTimeShort }}
                          </p>
                          <p v-else-if="deliveryToday" :key="index" class="delivery-time">
                              {{ packageElement.fromDate | formatTimeShort }}
                              -
                              {{ packageElement.toDate | formatTimeShort }}
                          </p>
                          <p v-else-if="deliveryTomorrow" :key="index" class="delivery-time">
                              {{ $t("message.tomorrow") }}
                          </p>
                          <p v-else-if="deliveryThisWeek" :key="index" class="delivery-time">
                              {{ getDayInText }}
                          </p>
                          <p v-else-if="deliveryNextWeekOrLater" :key="index" class="delivery-time">
                              {{
                        $t("message.inDays", {
                          days: calculateDaysBetween
                        })
                              }}
                          </p>
                      </template>
                  </template>

                  <p class="prettyDate" v-if="shipmentData.fromDate == null && shipmentData.toDate == null">
                      {{ shipmentData.toDate | formatPrettyDate(this.weekdays, this.$t("message.today")) }}
                  </p>
                  <p class="prettyDate" v-else-if="shipmentData.fromDate == null || deliveryToday">
                      {{ shipmentData.toDate | formatPrettyDate(this.weekdays, this.$t("message.today")) }}
                  </p>
                  <p class="prettyDate" v-else>
                      {{ shipmentData.toDate | formatPrettyDate(this.weekdays, this.$t("message.today")) }}
                      <span style="float: right;">
                          {{shipmentData.deliveryTimeFromAta | formatTimeShort }} - {{ shipmentData.deliveryTimeToAta | formatTimeShort }}
                      </span>
                  </p>


                  <p class="prettyDate" v-if="!showAta">
                  </p>
                  <p v-else-if="shipmentData.fromDate == null || deliveryToday" class="lowerPrettyDate">
                      {{ $t("message.lowerInfoTextCurrentDay") }}
                  </p>
                  <p v-else class="lowerPrettyDate">
                      {{ $t("message.lowerInfoTextNotToday") }}
                  </p>
              </template>
            </b-col>

            <b-col cols="12" :class="{'tracking-col': true, 'multi-package': packages.length > 1}">

                <template  v-if="!showAta">

                <h3>{{$t("message.eventHistoryText")}}</h3>
                <br/>

                </template>

                <div class="event-history" v-for="(packageElement, index) in packages" :key="index">

                    <h6 class="container-text" v-if="packages.length > 1">{{ $t("message.container") }} {{ index + 1 }}</h6>

                    <template v-if="packageElement.myShipmentSteps.length > 0">

                        <div v-for="(myShipmentStep, indexStep) in packageElement.myShipmentSteps" :key="indexStep">

                            <!-- This logic must be replaced, when we have 100% control over public codes-->
                            <!-- if hideShipmentStepDetails == false show all-->
                            <!-- if hideShipmentStepDetails == true,and packages.length > 1 show only 1 lates event, pr. package-->
                            <!-- if hideShipmentStepDetails == true,and packages.length <= 1 show only 4 latest events-->
                            <dl class="tracking-timeline row" v-if="!hideShipmentStepDetails || indexStep < (packages.length > 1 ? 1 : 4)">

                                <dt class="col-4 eventDescription col-xl-3">

                                    <span class="date">
                                        {{ myShipmentStep.date | formatDateShort }}
                                    </span>
                                    <br />

                                    <span class="time">
                                        {{ myShipmentStep.date | formatTimeShort }}
                                    </span>
                                </dt>
                                <dd class="col-8 eventDescription col-xl-9">
                                    <p class="headline">{{ trackingHistoryText(myShipmentStep) }}</p>
                                    <p class="description">{{ trackingHistoryTextDescription(myShipmentStep) }}</p>
                                </dd>

                            </dl>
                        </div>

                    </template>
                    <p v-else>
                        {{ $t("message.noeventsfound") }}
                    </p>

                </div>

                <b-button variant="link" v-if=" canHideShipmentStepDetails && hideShipmentStepDetails === true" class="full-history text-center" @click="hideShipmentStepDetails = false">
                    {{ $t("message.showfulleventhistory") }}
                    <b-icon-chevron-down></b-icon-chevron-down>
                </b-button>
                <b-button variant="link" v-if="canHideShipmentStepDetails && hideShipmentStepDetails === false" class="full-history text-center" @click="hideShipmentStepDetails = true">
                    {{ $t("message.hidefulleventhistory") }}
                    <b-icon-chevron-up></b-icon-chevron-up>
                </b-button>
            </b-col>

            <b-col cols="12" class="transportcompany-col">

              <span class="handledby-text">{{ $t("message.deliveryHandledBy") }} </span>
              <br/>
              <span class="company-name">{{ generalInformation.transportCompanyName }}</span>
              <br>
              
              <div class="tracking-links">
                <span class="tracking-link" v-for="(trackingLink, index) in generalInformation.trackAndTraceLinks" :key="index">
                <a @click="goToTrackAndTrace(trackingLink)" style="cursor: pointer;">
                  {{$t("message.followDelivery")}}
                  <template v-if="generalInformation.trackAndTraceLinks.length > 1" >({{$t("message.container")}} {{index+1}})</template>
                </a>
               </span>
              </div>
            </b-col>

            <!--
            <b-col cols="12" class="tracking-col">
              <h6>Hello cruel world</h6>
              <dl class="tracking-timeline row" v-for="(packageElement, index) in this.packages" :key="index">
                <template v-if="hideShipmentStepDetails">
                  <dt class="col-4 eventDescription col-xl-3">

                    <span class="date">
                                      {{ packageElement.myShipmentSteps[0].date | formatDateShort }}
                                  </span>
                    <br/>

                    <span class="time">
                                      {{ packageElement.myShipmentSteps[0].date | formatTimeShort }}
                                  </span>
                  </dt>
                  <dd class="col-8 eventDescription col-xl-9" :key="index + 'lower'">
                    {{ packageElement.myShipmentSteps[0].statusCode }}
                  </dd>
                </template>

                <template v-else-if="!hideShipmentStepDetails">
                  <template v-for="(trackEvent, innerIndex) in packageElement.myShipmentSteps">
                    <dt class="col-4 eventDescription col-xl-3" :key="innerIndex">
                                  <span class="date">
                                      {{ trackEvent.date | formatDateShort }}
                                  </span>
                      <br/>
                      <span class="time">
                                      {{ trackEvent.date | formatTimeShort }}
                                  </span>
                    </dt>
                    <dd class="col-8 eventDescription col-xl-9" :key="innerIndex + trackEvent.date">
                      <h6 v-if="innerIndex === 0">Pakke {{ index + 1 }}</h6>

                      {{ trackEvent.statusCode }}
                    </dd>
                  </template>
                </template>
              </dl>
              <b-form-checkbox v-model="hideShipmentStepDetails" checked="">
                <small>Hide Shipment Steps</small>
              </b-form-checkbox>

              TEST 
              
              <dl class="tracking-timeline row">
                <template
                    v-if="hideShipmentStepDetails && shipmentData.myShipmentSteps != null && shipmentData.myShipmentSteps.length > 0">
                  <dt class="col-4 eventDescription col-xl-3">

                    <span class="date">
                                      {{ shipmentData.myShipmentSteps[0].date | formatDateShort }}
                                  </span>
                    <br/>

                    <span class="time">
                                      {{ shipmentData.myShipmentSteps[0].date | formatTimeShort }}
                                  </span>
                  </dt>
                  <dd class="col-8 eventDescription col-xl-9">
                    <h6>Pakke 2</h6>
                    {{ shipmentData.myShipmentSteps[0].statusCode }}
                  </dd>
                </template>


                <template v-else-if="!hideShipmentStepDetails">
                  <template v-for="(trackEvent, index) in shipmentData.myShipmentSteps">
                    <dt class="col-4 eventDescription col-xl-3" :key="index">
                                  <span class="date">
                                      {{ trackEvent.date | formatDateShort }}
                                  </span>
                      <br/>
                      <span class="time">
                                      {{ trackEvent.date | formatTimeShort }}
                                  </span>
                    </dt>
                    <dd class="col-8 eventDescription col-xl-9" :key="index + trackEvent.date">
                      {{ trackEvent.statusCode }}
                    </dd>
                  </template>
                </template>
                <b-form-checkbox v-model="hideShipmentStepDetails" checked="">
                  <small>Hide Shipment Steps</small>
                </b-form-checkbox>
              </dl>

            </b-col>
-->

          </b-row>
        </b-col>
        <b-col cols="12" md="12" lg="4" order-md="3">

          <h3>{{ $t("message.needHelp") }}</h3>
          <br/>
          <p>{{ $t("message.needHelpDescription") }}</p>

          <p><!--(+45) 79 22 11 00--></p>

          <!-- <br />
          <br />

          <div class="upsell">
              <h6>
                  Brug for en ekstra hånd til leveringen ?
              </h6>
              <p>
                  Vi tilbyder at tage dit gamle udstyr med retur, og tilbyder også gerne hjælp med at bære op.
              </p>
              <ul class="list-unstyled">
                  <li><input type="checkbox" /> Opbæring</li>
                  <li><input type="checkbox" /> Bortskaffelse</li>
                  <li><b-button variant="primary">Bestil</b-button></li>
              </ul>
          </div> -->

        </b-col>
        <b-col cols="12" md="6" lg="4" order-md="2" class="productplacement-row">
          <img class="mx-auto d-block w-100" :src='iconLarge'/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

//import axios from 'axios';
//import shipmentApi from '../Services/httpService'
//import StepProgressBar from "@/components/StepProgressBar";
import { BIconChevronDown, BIconChevronUp } from 'bootstrap-vue'


export default {
  name: 'genericShipmentView',
  components: {BIconChevronDown, BIconChevronUp},
  props: {
    myShipment: {
      default: () => {},
      type: Object
    }
  },
  data: function () {
    return {
      shipmentData: {},
      clientId: "client",
      weekdays: [],
      delivered: false,
      deliveredAt: null,
      hideShipmentStepDetails: true,
      canHideShipmentStepDetails: true,
      packageTimingDiffer: false,
      showAta: false,
      generalInformation: {},
      packages: []

    }
  },
  async mounted() {

    this.weekdays = [
      this.$t("message.sunday"),
      this.$t("message.monday"),
      this.$t("message.tuesday"),
      this.$t("message.wednesday"),
      this.$t("message.thursday"),
      this.$t("message.friday"),
      this.$t("message.saturday")]
    
    const data = this.myShipment

    this.generalInformation = {
      customerId: data.customerId,
      trackAndTraceLinks: data.trackAndTraceLinks,
      transportCompanyName: data.transportCompanyName
    }
    this.packages = data.loadContainers;
    this.shipmentData = this.packages[0];

    const customerId = this.generalInformation.customerId.replace(/[-]/g, '').toUpperCase();
    if (customerId === 'D2BA3DCEDD5C4E13AE59A42318C7B47D' ||
       customerId === '2C2AD6E5C21B4A0BA6F219D514B8BA92')
    {
      this.clientId = "client" + customerId
    }
    else
    {
      this.clientId = "client";
    }
    
    this.calculateDelivery();
    // this.populateSteps();
    // this.makeDiffPackageArrival();
    this.packageTimingDiffer = this.checkIfPackageArrivalDiffer(this.packages);
    this.showAta = 
      this.shipmentData.fromDate != null || this.shipmentData.toDate != null ||
      this.shipmentData.deliveryTimeFromAta != null || this.shipmentData.deliveryTimeToAta != null 
  },
  methods: {
    makeDiffPackageArrival() {
      const packages = this.packages;

      packages[0].toDate = "2021-02-25T19:00:00"
    },
    populateSteps() {
      const packages = this.packages;

      for (let x = 0; x < packages.length; x++) {
        const packageElement = packages[x];

        for (let y = 1; y < 5; y++) {
          packageElement.myShipmentSteps[y] = packageElement.myShipmentSteps[0];
        }
      }
      
    },
    checkIfPackageArrivalDiffer(packages) {
      if (packages == null || packages.length === 0) return;
      let sameFromDate = packages.every(x => x.fromDate === packages[0].fromDate);
      let sameToDate = packages.every(x => x.toDate === packages[0].toDate);
      let sameAtaFromDate = packages.every(x => x.deliveryTimeFromAta === packages[0].deliveryTimeFromAta);
      let sameAtaToDate = packages.every(x => x.deliveryTimeToAta === packages[0].deliveryTimeToAta);


      return !(sameAtaFromDate && sameAtaToDate && sameFromDate && sameToDate);


    },
    calculateDelivery() {
      let steps = this.shipmentData.myShipmentSteps;

      for (let i = 0; i < steps.length; i++) {
          const currentStep = steps[i];
          
        if (currentStep.publicStatusCode === "P-6" || currentStep.publicStatusCode === "P-5") {
          this.delivered = true;
          this.deliveredAt = currentStep.date;
          break;
        } else if (currentStep.statusCode == "Afleveret" || currentStep.statusCode == "Afsluttet") {
          this.delivered = true;
          this.deliveredAt = currentStep.date;
          break;
        }
    
      }

    },
    goToTrackAndTrace(link) {
      window.open(link.trackingLink, '_blank')
    },
    trackingHistoryText(trackingEvent) {

        var statusTextToReturn = "";
        if (trackingEvent.publicStatusCode != undefined) {
            var translateKey = "publicTracking." + trackingEvent.publicStatusCode.replace('-', '');
            
            statusTextToReturn = this.$t(translateKey);
        }

        if (statusTextToReturn == "") {
            statusTextToReturn = trackingEvent.statusCode
        }

        return statusTextToReturn;
      },

    trackingHistoryTextDescription(trackingEvent) {

        var statusTextToReturn = "";
        if (trackingEvent.publicStatusCode != undefined) {
            var translateKey = "publicTrackingDescription." + trackingEvent.publicStatusCode.replace('-', '');
            
            statusTextToReturn = this.$t(translateKey);

            if (statusTextToReturn != "") {
                return statusTextToReturn;
            }

        }

        return null;
    },

  },
  computed: {
    iconSmall() {
      return require("@/assets/images/" + this.clientId + ".png")
    },
    iconLarge() {
      return require('@/assets/images/' + this.clientId + '-large.jpg')
    },
    deliveryTomorrow() {
      const deliveryDate = new Date(this.shipmentData.toDate)
      const deliveryDay = deliveryDate.toLocaleString(window.locale, {day: "numeric"})

      const dateNow = new Date();
      dateNow.setDate(dateNow.getDate() + 1)
      const dateTomorrow = dateNow.toLocaleString(window.locale, {day: "numeric"})


      if (deliveryDay == dateTomorrow && deliveryDate.getMonth() == dateNow.getMonth()) {
        return true
      } else {
        return false;
      }
    },
    deliveryToday() {
      const deliveryDate = new Date(this.shipmentData.toDate)
      const deliveryDay = deliveryDate.toLocaleString(window.locale, {day: "numeric"})

      const dateNow = new Date();
      const dateToday = dateNow.toLocaleString(window.locale, {day: "numeric"})

      if (deliveryDay === dateToday && deliveryDate.getMonth() === dateNow.getMonth()) {
        return true
      } else {
        return false;
      }
    },
    deliveryThisWeek() {
      return this.calculateDaysBetween > 6 ? false : true;
    },
    deliveryNextWeekOrLater() {
      return this.calculateDaysBetween > 6 ? true : false;
    },
    calculateDaysBetween() {
      const deliveryDate = new Date(this.shipmentData.toDate)
      deliveryDate.setHours(0)
      deliveryDate.setMinutes(0)
      deliveryDate.setSeconds(0)
      deliveryDate.setMilliseconds(0)

      const dateNow = new Date();
      dateNow.setHours(0)
      dateNow.setMinutes(0)
      dateNow.setSeconds(0)
      dateNow.setMilliseconds(0)

      const oneDay = 24 * 60 * 60 * 1000;

      const diffDays = Math.round(Math.abs((deliveryDate - dateNow) / oneDay));

      return diffDays;
    },
    getDayInText() {
      const toDate = new Date(this.shipmentData.toDate)

      return this.weekdays[toDate.getDay()]
    }

  },
  filters: {
    formatDateShort(date) {
      const realDate = new Date(date);
      const shortDate = realDate.toLocaleString(window.locale, {day: 'numeric', month: 'short'})
      return shortDate
    },
    formatDateLong(date) {
        const realDate = new Date(date);
        const shortDate = realDate.toLocaleString(window.locale, { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' })
      return shortDate
      },
    formatTimeShort(date) {
      const realDate = new Date(date);
      const hour = realDate.getHours();
      let minutes = realDate.getMinutes();

      if (minutes < 10) {
        minutes = "0" + minutes
      }


      return hour + ':' + minutes

    },
    formatPrettyDate(date, weekdays, messageToday) {
      const realDate = new Date(date)
      const dateNow = new Date();

      if (realDate.getDate() == dateNow.getDate() &&
          realDate.getMonth() == dateNow.getMonth() &&
          realDate.getFullYear() == dateNow.getFullYear()) {
        return messageToday + ", " + weekdays[realDate.getDay()]
      }

      return weekdays[realDate.getDay()] + " " + realDate.toLocaleString(window.locale, {
        day: 'numeric',
        month: 'short'
      })

    }
  }

}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background-color: #FDF9F8;
}

.multipleDate {
  font-size: 3rem !important;
}

.prettyDate {
  font-size: 1.2rem;
  text-align: left;

}

.lowerPrettyDate {
  font-size: 1rem;
  text-align: left;
  color: rgb(128, 147, 189);  
  line-height: 1.5rem;
}

.wrapper {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  background-color: white;
  border-radius: 0.5rem;
  min-height: 80vh;
  flex: 1 1 auto;
  padding: 0 2.5rem 2.5rem 2.5rem;
  margin-top: 5vh;
}

.wrapper > .row {
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-row {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: #d0d0d0 solid 0.1rem;
}

.primary-row > div {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.ETADiv {
  /*height: 20vh;*/
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 2rem;
}


.ETADiv .delivered-text {
    font-size: 30px;
}

.ETADiv .delivered-date {
    font-size: 15px;
}

.ETADiv > p {
  padding: 0;
  margin: 0;
}

.ETADiv > p:nth-child(1) {
  text-transform: uppercase;
  letter-spacing: 3px;
  white-space: normal;
}

.package-title {
  margin: 5px 0 0 0;
}

.delivery-time {
  text-transform: capitalize;
  font-size: 3rem;
  /*line-height: 7rem;*/
  font-weight: 300;
  margin: 10px 0 10px 0 !important;
}


.tracking-timeline span.date {
  font-size: 1.2rem;
  font-weight: 500;
}

.tracking-timeline span.date {
  font-size: 1.2rem;
  font-weight: 500;
}

.tracking-timeline span.time {
  font-size: 1.1rem;
  font-weight: 500;
  color: #929292;
}

.tracking-timeline dt, .tracking-timeline dd {
  padding: 0.5rem 0.5rem;
}

.tracking-timeline dd {
  border-left: #d0d0d0 solid 0.1rem;
}

.tracking-timeline .eventDescription {
  font-size: 1.2rem;
  font-weight: 500;
}


.eventDescription {
    margin:0;
}
.eventDescription p {
    margin:0;
}

.eventDescription p.headline {

}

.eventDescription p.description {
    color: #929292;
    font-size: 0.8rem;
    margin-top: 0.3rem;
    line-height: 1.1rem;
}



.event-history .container-text {
    text-align: left;
    color: #929292;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: normal;
}

.tracking-col button.full-history{
    text-align:center;
    font-size:0.7rem;
    color: #929292;
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: normal;
}
.tracking-col .headline-text {
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: normal;
    font-weight: 500;
    font-size: 0.7rem;
    color: #929292;
    padding-top: 2rem;
}

.transportcompany-col {
  padding-left: 0;
  padding-right: 0;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.transportcompany-col span {
  padding: 0.5rem;
}

.transportcompany-col .handledby-text{
  text-transform: uppercase;
  letter-spacing: 3px;
  white-space: normal;
  font-weight: 500;
  font-size: 0.7rem;
  color: #929292;
}

.transportcompany-col .company-name {
  font-weight: 500;
  font-size: 1.2rem;
  color: #929292;
}

.transportcompany-col .tracking-links {
  font-weight: 700;
  font-size: 1.2rem;
  padding-top: 0.8em;
}

.productplacement-row {
    max-height: 773px;
}

.productplacement-row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.upsell button {
  margin-top: 1rem;
}

.tracking-link a {
    display:inline;
}
.tracking-link a:after {
    content:"\a";
    white-space: pre;
}

@media screen and (max-width: 576px) {
  .wrapper {
    margin-top: 0vh;
  }

  .wrapper > .row {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

@media screen and (max-width: 405px) {
  .delivery-time {
    font-size: 2.5rem !important;
  }

  .ETADiv > p:nth-child(4) {
    font-size: 1rem;
  }
}

@media screen and (max-width: 320px) and (min-width: 280px) {
  .ETADiv > p:nth-child(2),
  .ETADiv > p:nth-child(3) {
    font-size: 2rem !important;
  }

  .ETADiv > p:nth-child(1) {
    font-size: 0.9rem;
  }

  .prettyDate {
    font-size: 1rem;
  }

.lowerPrettyDate {
    font-size: 0.8rem;
}

/*  .tracking-timeline dt, .tracking-timeline dd {
    padding: 0.5rem 0.5rem 0.5rem 0rem;
  }*/

  .transportcompany-col .handledby-text{
     font-size: 0.6rem;
  }

}

@media screen and (max-width: 576px) and (min-width: 406px) {
  .delivery-time {
    font-size: 2.5rem !important;
    /*line-height: 5rem;*/
  }

  .prettyDate {
    font-size: 1rem;
  }

  .lowerPrettyDate {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 577px) {
  .delivery-time {
    font-size: 4rem !important;
  }
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .delivery-time {
    font-size: 2.5rem !important;
  }
}

div.client2C2AD6E5C21B4A0BA6F219D514B8BA92 .ETADiv {
  background-color: rgb(22, 39, 78);
  color: white;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .3);
}

div.client2C2AD6E5C21B4A0BA6F219D514B8BA92 a {
  color: rgb(22, 39, 78);
}

div.client2C2AD6E5C21B4A0BA6F219D514B8BA92 button.btn.btn-primary {
  color: rgb(22, 39, 78) !important;
  background-color: rgb(22, 39, 78) !important;
  border-color: rgb(22, 39, 78) !important;
  color: white !important;
}


div.clientD2BA3DCEDD5C4E13AE59A42318C7B47D .ETADiv {
  background-color: rgb(0, 150, 106);
  color: white;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .3);
}

div.clientD2BA3DCEDD5C4E13AE59A42318C7B47D a {
  color: rgb(0, 150, 106);
}

div.clientD2BA3DCEDD5C4E13AE59A42318C7B47D button.btn.btn-primary {
  color: rgb(0, 150, 106) !important;
  background-color: rgb(0, 150, 106) !important;
  border-color: rgb(0, 150, 106) !important;
  color: white !important;
}

div.client .ETADiv {
  background-color: rgb(0, 146, 95);
  color: white;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .3);
}

div.client a {
  color: rgb(0, 150, 106);
}

div.client button.btn.btn-primary {
  color: rgb(0, 146, 95) !important;
  background-color: rgb(0, 146, 95);
  border-color: rgb(0, 146, 95) !important;
  color: white !important;
}


</style>
