<template>
  <div id="app">
    <view-switcher/>
  </div>
</template>

<script>
import viewSwitcher from "@/components/viewSwitcher";


export default {
  name: 'App',
  data:function ()  {
    return {
      gotId: false
    }
  },
  components: {

    viewSwitcher
  },
  mounted() {
    document.title = "Shipment"

    const localeCode = navigator.language;

    this.$root.$i18n.locale = localeCode.split('-')[0];
    window.locale = localeCode;
      
  },
}
</script>

<style>
body {
    background-color: #FDF9F8;
}


#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

@media screen and (max-width: 576px) {
  #app
    {
        margin-top: 0px;
    }
}
  
</style>
